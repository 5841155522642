// 基准大小
const baseSize = 100;
// 是否使用固定的 100px 字体大小
const useFixedFontSize = false;

// 设置 rem 函数
function setRem() {
  // 判断是否需要使用固定的 100px
  if (useFixedFontSize) {
    document.documentElement.style.fontSize = "100px";
  } else {
    // 当前页面宽度相对于 1920 宽的缩放比例，可根据自己需要修改。
    const scale = document.documentElement.clientWidth / 414;
    // 设置页面根节点字体大小
    document.documentElement.style.fontSize =
      baseSize * Math.min(scale, 2) + "px";
  }
}

// 初始化
setRem();

// 改变窗口大小时重新设置 rem
window.onresize = function() {
  setRem();
};
