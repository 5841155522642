<script>
import {
  onMounted,
  toRefs,
  ref,
  computed,
  onUnmounted,
  reactive,
  onBeforeMount,
} from "vue";

export default {
  setup() {
    const state = reactive({
      prizeList: [
        {
          name: "iphone15",
          pic: require("@/assets/iPhone5.png"),
        },
        {
          name: "Try Again",
          pic: require("@/assets/thanks.png"),
        },
        {
          name: "100",
          pic: require("@/assets/onem.png"),
        },
        {
          name: "Try Again",
          pic: require("@/assets/thanks.png"),
        },
        {
          name: "100",
          pic: require("@/assets/onem.png"),
        },
        {
          name: "Try Again",
          pic: require("@/assets/thanks.png"),
        },
        {
          name: "10000",
          pic: require("@/assets/onew.png"),
        },
        {
          name: "Try Again",
          pic: require("@/assets/thanks.png"),
        },
        {
          name: "100",
          pic: require("@/assets/onem.png"),
        },
        {
          name: "Try Again",
          pic: require("@/assets/thanks.png"),
        },
        {
          name: "8000",
          pic: require("@/assets/eight.png"),
        },
        {
          name: "100",
          pic: require("@/assets/onem.png"),
        },
      ], // 后台配置的奖品数据
      isRunning: false, // 是否正在抽奖
      baseRunAngle: 360 * 5, // 总共转动角度 至少5圈
      prizeId: 0, // 中奖id
    });
    const prizeWrap = ref(null);
    const show = ref(false);

    const changeShow = () => {
      show.value = false;
      window.open("https://victory-play.com");
    };

    // 平均每个奖品角度
    const rotateAngle = computed(() => {
      const _degree = 360 / state.prizeList.length;
      return _degree;
    });

    // 要执行总角度数
    const totalRunAngle = computed(() => {
      return (
        state.baseRunAngle +
        360 -
        state.prizeId * rotateAngle.value -
        rotateAngle.value / 2
      );
    });

    // 计算绘制转盘背景
    const bgColor = computed(() => {
      const _len = state.prizeList.length;
      const colorList = ["#52340a", "#a16514"];
      let colorVal = "";
      for (let i = 0; i < _len; i++) {
        colorVal += `${colorList[i % 2]} ${rotateAngle.value * i}deg ${
          rotateAngle.value * (i + 1)
        }deg,`;
        console.log(colorVal, _len);
      }
      return `
              background: conic-gradient(${colorVal.slice(0, -1)});
            `;
    });
    // 每个奖品布局
    const prizeStyle = computed(() => {
      const _degree = rotateAngle.value;
      return (i) => {
        return `
                width: ${
                  2 * 270 * Math.sin(((_degree / 2) * Math.PI) / 18000)
                }rem;
                height: ${270 / 100}rem;
                transform: rotate(${_degree * i + _degree / 2}deg);
                transform-origin: 50% 100%;
              `;
      };
    });
    onMounted(() => {
      prizeWrap.value.style = `${bgColor.value} transform: rotate(-${
        rotateAngle.value / 2
      }deg)`;
    });
    let timer = null;
    onMounted(() => {
      const scroll = () => {
        const footBarDom = document.getElementById("footBarDom");
        if (footBarDom.scrollHeight <= footBarDom.clientHeight) return;
        if (footBarDom.clientHeight + footBarDom.scrollTop >= 400) {
          footBarDom.scrollTop = 0;
        } else {
          footBarDom.scrollTop++;
        }
      };
      timer = setInterval(() => {
        scroll();
      }, 50);
    });
    onBeforeMount(() => {
      clearInterval(timer);
      timer = null;
    });

    onUnmounted(() => {
      prizeWrap.value.removeEventListener("transitionend", stopRun);
    });

    // 获取随机数
    const getRandomNum = () => {
      const num = Math.floor(Math.random() * state.prizeList.length);
      return num;
    };

    const start = () => {
      if (!state.isRunning) {
        state.isRunning = true;

        console.log("开始抽奖，后台请求中奖奖品");
        // 请求返回的奖品编号 这里使用随机数
        const prizeId = getRandomNum();
        console.log("中奖ID>>>", prizeId, state.prizeList[prizeId]);
        // alert(prizeId, state.prizeList[prizeId].name.value)
        state.prizeId = prizeId;
        startRun();
      }
    };

    const startRun = () => {
      console.log(state.isRunning, totalRunAngle.value);
      // 设置动效
      prizeWrap.value.style = `
              ${bgColor.value}
              transform: rotate(${totalRunAngle.value}deg);
              transition: all 4s ease;
            `;
      // 监听transition动效停止事件
      prizeWrap.value.addEventListener("transitionend", stopRun);
    };

    const stopRun = (e) => {
      console.log(e);
      state.isRunning = false;
      show.value = true;
      prizeWrap.value.style = `
              ${bgColor.value}
              transform: rotate(${totalRunAngle.value - state.baseRunAngle}deg);
            `;
    };
    const randomCoding = () => {
      //创建26个字母数组
      const arr = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      let idvalue = "";
      let res = "";
      const n = 3;
      for (let i = 0; i < n; i++) {
        idvalue += arr[Math.floor(Math.random() * 26)];
      }
      let A = Math.floor(Math.random() * 10);
      let B = idvalue[1].toLocaleLowerCase();
      let C = idvalue[2].toLocaleLowerCase();
      res = "0" + A + "*" + idvalue[0] + B + C + "****";
      return res;
    };
    const PEO_LIST = Array.from({ length: 20 }, () => randomCoding());


    return {
      ...toRefs(state),
      bgColor,
      show,
      prizeStyle,
      prizeWrap,
      start,
      changeShow,
      PEO_LIST,
    };
  },
};
</script>

<template>
  <div class="wrapper">
    <div class="topBar">WHEEL OF FORTUNE</div>
    <div class="title">Try your luck now!</div>
    <div class="container">
      <div class="prize-list" ref="prizeWrap" :style="bgColor">
        <div
          class="prize-item"
          v-for="(item, index) in prizeList"
          :key="index"
          :style="prizeStyle(index)"
        >
          <img :src="item.pic" alt="" />
          <p>{{ item.name }}</p>
        </div>
      </div>
      <div class="btn" @click="start"></div>
    </div>
    <div class="toast" v-if="show">
      <div class="toast__box">
        <div class="toast__box__img">
          <img src="./assets/tc.png" alt="" style="width: 400px" />
          <span class="toast__box__content">
            Congratulation! <br />You've won an award<br />
            Click <a href="https://victory-play.com" target="_blank">here</a> to
            claim your prize
          </span>
        </div>
        <div class="toast__box__btn" @click="changeShow">
          <img src="./assets/bx.png" alt="" style="width: 200px" />
        </div>
      </div>
    </div>

    <div class="footBar">
      <div class="footBar__title">Winner List</div>
      <div class="footBar__container" id="footBarDom">
        <div
          class="footBar__content"
          v-for="(item, index) of PEO_LIST"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  background-color: #2a442c;
  overflow-y: auto;
}
.topBar {
  height: 50px;
  background-color: black;
  color: white;
  font-size: 20px;
  text-align: center;
  line-height: 50px;
}
.title{
  height: 115px;
  flex: 1;
  font-size: 40px;
  text-align: center;
  line-height: 200px;
  font-weight: bold;
  color: #edb569;
}
.container {
  flex: 1;
  width: 100%;
  position: relative;
}
.prize-list {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  border: 10px solid #edb569;
  overflow: hidden;
  margin: auto;
}
.prize-item {
  /* border: 2px solid red; */
  position: absolute;
  left: 0;
  right: 0;
  top: -80px;
  margin: auto;
}
.prize-item img {
  width: 30%;
  height: 15%;
  margin: 90px auto 10px;
  display: block;
}
.prize-item p {
  color: #fff;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
}
.btn {
  width: 130px;
  height: 130px;
  background: url("./assets/anniu.png") no-repeat center / 100% 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
}
.btn::before {
  content: "";
  width: 41px;
  height: 39px;
  background: url("https://www.jq22.com/demo/jquerylocal201912122316/img/icon_point.png")
    no-repeat center / 100% 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: -33px;
  margin: auto;
}
.toast {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.toast__box {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.toast__box__img {
  margin-bottom: -30px;
  padding-left: 11px;
  position: relative;
}
.toast__box__content {
  position: absolute;
  top: 76px;
  left: 76px;
  font-size: 20px;
  line-height: 40px;
}
.toast__box__btn {
  padding-left: 120px;
}
.footBar {
  flex: 1;
  width: auto;
  text-align: center;
  font-size: 20px;
  background-image: url("./assets/zjmd.png");
  background-repeat: no-repeat;
  background-position-x: -70px;
  background-position-y: 60px;
  background-size: 570px 300px;
  padding-top: 40px;
}
.footBar__title {
  font-size: 40px;
  padding-bottom: 38px;
  color: #edb569;
}
.footBar__container {
  width: 100%;
  height: 150px;
  overflow-y: scroll;
}
.footBar__content {
  font-size: 20px;
  line-height: 30px;
}
</style>
